import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Footer from '../../../blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import ContentColumn from "../../../blocks/Content/Column/Column";
import Hero from '@solid-ui-blocks/Hero/Block02'

const Post2 = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Campaña de fraude internacional'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']} />
            {/* Blocks */}
            <Header content={content['header-light']}/>
            <Divider space='6'/>
            <Hero content={content['hero']} reverse />
            <Divider space='5'/>
            <ContentColumn content={content['content-one']}/>
            <Divider space='5'/>
            <Contact content={content['cta']}/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSitePost2BlockContent {
    allBlockContent(
      filter: { page: { in: ["site/blog/post2-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Post2
